import React from 'react';
import dynamic from 'next/dynamic';

import { Typography } from '@zydalabs/zac-react';
import useTranslation from 'next-translate/useTranslation';
import { StyledPickupWrapper, StyledPickupBranchesWrapper } from './style';
import { useSelectPickUpBranch } from '../../hooks';
import { StyledHeaderWrapper } from '../DeliveryAndAddressList/style';

const BranchesComponent = dynamic(() => import('common/components/Branches'));

const PickupList: React.FC<{
  mode: string;
}> = ({ mode }) => {
  const onBranchSelected = useSelectPickUpBranch();
  const { t } = useTranslation('branches');
  return (
    <StyledPickupWrapper>
      <StyledHeaderWrapper>
        <Typography variant="heading18" mb={0} mt={0}>
          {`${t('address:select')} ${t('branch')}`}
        </Typography>
      </StyledHeaderWrapper>
      <StyledPickupBranchesWrapper>
        <BranchesComponent onBranchSelected={branchId => onBranchSelected({ branchId, mode })} pickupEnabled />
      </StyledPickupBranchesWrapper>
    </StyledPickupWrapper>
  );
};

export default PickupList;
