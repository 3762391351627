import React from 'react';
import dynamic from 'next/dynamic';

import { Typography } from '@zydalabs/zac-react';
import useTranslation from 'next-translate/useTranslation';
import { StyledBeachWrapper, StyledBeachBranchesWrapper } from './style';
import { useSelectPickUpBranch } from '../../hooks';
import { StyledHeaderWrapper } from '../DeliveryAndAddressList/style';

const BranchesComponent = dynamic(() => import('common/components/Branches'));

const BeachList: React.FC<{
  mode: string;
}> = ({ mode }) => {
  const onBranchSelected = useSelectPickUpBranch();
  const { t } = useTranslation('branches');
  return (
    <StyledBeachWrapper>
      <StyledHeaderWrapper>
        <Typography variant="heading18" mb={0} mt={0}>
          {`${t('address:select')} ${t('branch')}`}
        </Typography>
      </StyledHeaderWrapper>
      <StyledBeachBranchesWrapper>
        <BranchesComponent onBranchSelected={branchId => onBranchSelected({ branchId, mode })} beachOrderEnabled />
      </StyledBeachBranchesWrapper>
    </StyledBeachWrapper>
  );
};

export default BeachList;
