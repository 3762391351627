import React, { useEffect, useState } from 'react';
import { Icon, useTheme } from '@zydalabs/storefront-components';
import { Button, Typography } from '@zydalabs/zac-react';
import useTranslation from 'next-translate/useTranslation';

import { useAddressBook } from 'service/hooks';
import { changeRoute, customHistory } from 'common/utils';
import { URLS } from 'common/constants';
import ServiceConfigs from 'service/config';
import { usePersistedSelectedCoordinates } from 'common/hooks';
import { DeliveryMap } from 'modules/userModule/components';

import { IcLocation } from 'common/icons';
import DeliveryList from '../DeliveryList';
import { StyledHeaderWrapper } from './style';
import AddressList from '../../../userModule/components/AddressList';
import { StyledLinkWrapper } from '../../../userModule/components/UserBookList/style';

enum DELIVERY_LISTS {
  AREA_LIST = 'AREA_LIST',
  ADDRESS_LIST = 'ADDRESS_LIST',
}

const DeliveryAndAddressList: React.FC = () => {
  const theme = useTheme();
  const [currentDeliveryList, setCurrentDeliveryList] = useState<DELIVERY_LISTS>();
  const useDeliveryzonesMs = ServiceConfigs?.getUseDeliveryzonesMs();
  const { data: addressBook, isLoading } = useAddressBook();
  const [persistedSelectedCoords] = usePersistedSelectedCoordinates();
  const { t } = useTranslation('address');

  const { addresses } = addressBook || {};

  useEffect(() => {
    if (!isLoading) {
      if (addresses?.length) setCurrentDeliveryList(DELIVERY_LISTS.ADDRESS_LIST);
      else setCurrentDeliveryList(DELIVERY_LISTS.AREA_LIST);
    }
  }, [addresses, isLoading]);

  const handleDeliverToNewAreaClick = () => {
    if (useDeliveryzonesMs && persistedSelectedCoords?.lat && persistedSelectedCoords?.lng) changeRoute(URLS.NEW_ADDRESS);
    else setCurrentDeliveryList(DELIVERY_LISTS.AREA_LIST);
  };

  const handleOnAfterSelectAddress = (branchId: string): void =>
    customHistory.handleOrderModeRouting({
      branchId,
    });

  if (currentDeliveryList === DELIVERY_LISTS.ADDRESS_LIST) {
    return (
      <>
        <StyledHeaderWrapper>
          <Typography variant="heading18" mb={0} mt={0}>
            {`${t('select')} ${t('address')}`}
          </Typography>
        </StyledHeaderWrapper>
        <StyledLinkWrapper>
          <Button
            variant="ghost"
            text={t('deliverToNewArea')}
            testId="switch_to_area_btn"
            startIcon={<Icon icon={IcLocation} color={theme.color.link} />}
            onClick={handleDeliverToNewAreaClick}
          />
        </StyledLinkWrapper>
        <AddressList showAddLink={false} onAfterSelectAddress={handleOnAfterSelectAddress} />
      </>
    );
  }
  if (useDeliveryzonesMs && currentDeliveryList === DELIVERY_LISTS.AREA_LIST) {
    return <DeliveryMap />;
  }
  if (!useDeliveryzonesMs && currentDeliveryList === DELIVERY_LISTS.AREA_LIST) {
    return (
      <>
        <StyledHeaderWrapper>
          <Typography variant="heading18">{`${t('select')} ${t('area')}`}</Typography>
        </StyledHeaderWrapper>
        <DeliveryList />
      </>
    );
  }
  // TODO: Shimmer effect till address query is resolved
  return <></>;
};

export default DeliveryAndAddressList;
