import styled from 'styled-components';

export const StyledPickupWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const StyledPickupBranchesWrapper = styled.div`
  ${({ theme }) => `
padding: 0 ${theme.space[4]}px;
background-color: ${theme.color.white}
`}
`;
