import styled from 'styled-components';

export const StyledModesTabWrapper = styled.div`
  height: 100%;
  & > div {
    display: flex;
    flex-direction: column;
    height: 100%;
    > div:first-child {
      ${({ theme }) => `
      margin: 0 ${theme.space[4]}px;
      border-bottom: 1px solid ${theme.color.border};
      background-color: ${theme.color.white}
      `}
    }
    > div:nth-child(2) {
      height: 100%;
    }
  }
`;
