import { useEffect, useRef } from 'react';
import { useRouter } from 'next/router';

import { FULFILLMENT_MODES, URLS } from 'common/constants';
import {
  usePersistedBranchId,
  usePersistedOrderMode,
  usePersistedAreaId,
  usePersistedUserAddressId,
  useGlobalOrderTypeAndTime,
  usePersistedRecipientInfo,
  usePersistedSupportedDeliveryZone,
  usePersistedSelectedCoordinates,
  usePersistedAreaTitle,
} from 'common/hooks';
import { useUpdateCart } from 'modules/productsModule/hooks';
import { customHistory, replaceRoute } from '../../../../common/utils';

type OnBranchSelectedType = ({ branchId, mode }: { branchId: string; mode: string }) => void;

// TODO Remove afterBranchSelection prop after custom history stack gets merged
type UseSelectPickUpBranchType = (afterBranchSelection?: () => void) => OnBranchSelectedType;
const useSelectPickUpBranch: UseSelectPickUpBranchType = afterBranchSelection => {
  const router = useRouter();
  const [persistedBranchId, setPersistedBranchId] = usePersistedBranchId();
  const [persistedOrderMode, setPersistedOrderMode] = usePersistedOrderMode();
  const [, , removePersistedArea] = usePersistedAreaId();
  const [, , removePersistedSupportedDeliveryZone] = usePersistedSupportedDeliveryZone();
  const [, , removePersistedUserAddressId] = usePersistedUserAddressId();
  const [, , removePersistedRecipientInfo] = usePersistedRecipientInfo();
  const [, , removePersistedSelectedCoordinates] = usePersistedSelectedCoordinates();
  const [, , removePersistedAreaTitle] = usePersistedAreaTitle();

  const updateCart = useUpdateCart();

  const isUserChoseBranch = useRef(false);
  const [calculatedOrderTime] = useGlobalOrderTypeAndTime();

  useEffect(() => {
    if (!isUserChoseBranch.current || !calculatedOrderTime || calculatedOrderTime.isLoading) return;
    const { isScheduledEnabled, fulfillmentTimeType } = calculatedOrderTime;
    isUserChoseBranch.current = false;

    if (!fulfillmentTimeType && isScheduledEnabled) replaceRoute({ pathname: URLS.ESTIMATED_TIME, query: { ...router.query } });
    else if (afterBranchSelection) {
      afterBranchSelection();
    } else {
      customHistory.handleOrderModeRouting({
        branchId: persistedBranchId,
      });
    }
  }, [calculatedOrderTime]);

  const onBranchSelected: OnBranchSelectedType = async ({ branchId, mode }) => {
    if (persistedOrderMode === mode && persistedBranchId === branchId) {
      if (afterBranchSelection) afterBranchSelection();
      else customHistory.back();
      return;
    }
    isUserChoseBranch.current = true;
    await setPersistedBranchId(branchId);
    await setPersistedOrderMode(mode);
    removePersistedSupportedDeliveryZone();
    removePersistedArea();
    removePersistedUserAddressId();
    removePersistedRecipientInfo();
    removePersistedSelectedCoordinates();
    removePersistedAreaTitle();

    if (calculatedOrderTime?.isCartAvailable) {
      const updateCartVars = {
        branchId,
        deliveryType: mode === FULFILLMENT_MODES.BEACH ? FULFILLMENT_MODES.BEACH : FULFILLMENT_MODES.PICKUP,
      };
      await updateCart(updateCartVars);
    }
    calculatedOrderTime?.recalculate?.();
  };

  return onBranchSelected;
};

export default useSelectPickUpBranch;
